/**
 * Created by PULSARINARA\d.monfort on 30/07/21.
 */


// $(document).ready(function () {
//     $('#personalise').on('change', function () {
//         if ($(this).is(':checked')) {
//             $('.personalise_check_show').show();
//             $('.repeats_check_hide').hide();
//
//             $('.select_option').hide();
//             switch ($('#repeat_rule').val()) {
//                 //day
//                 case "1":
//
//                     break;
//                 //week
//                 case "2":
//                     $('#repeat_week').show();
//                     break;
//                 //month
//                 case "3":
//                     $('#repeat_month').show();
//                     break;
//                 //year
//                 case "4":
//                     break;
//             }
//         } else {
//             $('.personalise_check_hide').hide();
//             $('.repeats_check_show').show();
//         }
//
//
//     });
// });

$(document).ready(function () {
    switch ($('#repeat_rule').val()) {
        case "1":
            break;
        //week
        case "2":
            $('#repeat_week').show();
            break;
        //month
        case "3":
            $('#repeat_month').show();
            break;
        //year
        case "4":
            break;
    }



    var day = $('#end_fecha').val();
    var time = $('#end_time').val();
    var date = day + ' ' + time + ':00';
    var sub = new Date(date);


    var day2 = $('#start_date').val();
    var time2 = $('#start_time').val();
    var date2 = day2 + ' ' + time2 + ':00';
    var init = new Date(date2);


    sub.setHours(sub.getHours() - init.getHours());
    sub.setMinutes(sub.getMinutes() - init.getMinutes());

    var hora = sub.getHours();
    var minutos = sub.getMinutes();
    hora = (hora < 10 ? "0" : "") + hora;
    minutos = (minutos < 10 ? "0" : "") + minutos;

    if (isNaN(hora)){
        hora = 0;
    }
    if (isNaN(minutos)){
        minutos=0;
    }

    if ($("#hours_added").val()){
    document.getElementById("hours_added").value = hora;
    }
    if ($("#minutes_added").val()){
    document.getElementById("minutes_added").value = minutos;
    }
});


$(document).ready(function () {
    $('#repeat_rule').change(function () {
        $('.select_option').hide();
        switch ($(this).val()) {
            case "1":
                break;
            //week
            case "2":
                $('#repeat_week').show();
                break;
            //month
            case "3":
                $('#repeat_month').show();
                break;
            //year
            case "4":
                break;
        }


    });
});


$(document).ready(function () {
    var day = $('#start_date').val();

    if ($('#month_result').val()==""){
    $('#month_result').val(day.substring(8));
    }

    $('#start_date').change(function () {
        day = $('#start_date').val();

        // alert(day);
        $('#month_result').val(day.substring(8));
    });

});

$(document).ready(function () {
    $('#hours_added').change(fechas);

    $('#minutes_added').change(fechas);

});

function fechas() {
    const date_day = $('#start_date').val();
    const time = $('#start_time').val();
    const date = date_day + ' ' + time + ':00';
    var init = new Date(date);

    if ($('#hours_added').val() == "" || $('#hours_added').val() < 0) {

        return;
    } else {

        var hours = $('#hours_added').val();
    }
    if ($('#minutes_added').val() == "" || $('#minutes_added').val() < 0) {
        return;
    } else {
        var minutes = $('#minutes_added').val();
    }

    while (hours / 24 >= 1) {
        hours = hours - 24;
        init.setDate(init.getDate() + 1);
    }

    while (minutes / 60 >= 1) {
        minutes = minutes - 60;
        init.setHours(init.getHours() + 1);
    }

    const date2 = date_day + ' ' + hours + ':' + minutes + ':00';
    var add = new Date(date2);
    init.setHours(init.getHours() + add.getHours());
    init.setMinutes(init.getMinutes() + add.getMinutes());


    var hora = init.getHours();
    var minutos = init.getMinutes();
    var day = ("0" + init.getDate()).slice(-2);
    var month = ("0" + (init.getMonth() + 1)).slice(-2);

    hora = (hora < 10 ? "0" : "") + hora;
    minutos = (minutos < 10 ? "0" : "") + minutos;
    document.getElementById("end_fecha").value = init.getFullYear()+"-" + month + '-' + day;
    document.getElementById("end_time").value = hora + ':' + minutos;
}


$(document).ready(function () {
    $('#end_time').change(function () {
        var day = $('#end_fecha').val();
        var time = $('#end_time').val();
        var date = day + ' ' + time + ':00';
        var sub = new Date(date);


        var day2 = $('#start_date').val();
        var time2 = $('#start_time').val();
        var date2 = day2 + ' ' + time2 + ':00';
        var init = new Date(date2);


        sub.setHours(sub.getHours() - init.getHours());
        sub.setMinutes(sub.getMinutes() - init.getMinutes());

        var hora = sub.getHours();
        var minutos = sub.getMinutes();
        hora = (hora < 10 ? "0" : "") + hora;
        minutos = (minutos < 10 ? "0" : "") + minutos;

        document.getElementById("hours_added").value = hora;
        document.getElementById("minutes_added").value = minutos;
    });

});





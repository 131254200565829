/* COLOR PICKER */

// $(document).ready(function () {
//
//     init_ColorPicker();
// });

function init_ColorPicker() {
    console.log('init_ColorPicker', typeof ($.fn.colorpicker));

    if (typeof ($.fn.colorpicker) === 'undefined') {
        return;
    }

    $('.colorpicker').colorpicker();
    $('.demo2').colorpicker();

    $('#demo_forceformat').colorpicker({
        format: 'rgba',
        horizontal: true
    });

    $('#demo_forceformat3').colorpicker({
        format: 'rgba',
    });

    $('.demo-auto').colorpicker();
};
(function ($) {
    "use strict";

    var chart_settings = {
        series : {
            lines     : {
                show: false,
                fill: true
            },
            splines   : {
                show     : true,
                tension  : 0.4,
                lineWidth: 1,
                fill     : 0.4
            },
            points    : {
                radius: 0,
                show  : true
            },
            shadowSize: 2
        },
        grid   : {
            verticalLines: true,
            hoverable    : true,
            clickable    : true,
            tickColor    : "#d5d5d5",
            borderWidth  : 1,
            color        : '#fff'
        },
        colors : ["rgba(38, 185, 154, 0.38)", "rgba(3, 88, 106, 0.38)"],
        xaxis  : {
            tickColor              : "rgba(51, 51, 51, 0.06)",
            mode                   : "time",
            tickSize               : [1, "day"],
            //tickLength: 10,
            axisLabel              : "Date",
            axisLabelUseCanvas     : true,
            axisLabelFontSizePixels: 12,
            axisLabelFontFamily    : 'Verdana, Arial',
            axisLabelPadding       : 10
        },
        yaxis  : {
            ticks    : 8,
            tickColor: "rgba(51, 51, 51, 0.06)",
        },
        tooltip: false
    };

    $(document).ready(function () {
        if ($('#chart_bounces').length) {
            init_bounces_reportrange();
            getData(function (data) {
                $.plot($("#chart_bounces"), [data.permanent, data.temporal], chart_settings);
            });
        }
    });

    /**
     *
     * @param from
     * @param to
     * @param callback
     */
    var getData = function (callback) {
        pulsar.ajax({
            url    : $("#chart_bounces").data('url'),
            data   : {
                from: null,
                to  : null
            },
            success: function (data) {
                if (data.result && callback) {
                    callback(data);
                }
            }
        });
    };

    function init_bounces_reportrange() {

        if (typeof ($.fn.daterangepicker) === 'undefined') {
            return;
        }
        //console.log('init_daterangepicker');

        var cb = function (start, end, label) {
            //console.log(start.toISOString(), end.toISOString(), label);
            $('#chart_bounces_range span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        };

        var optionSet1 = {
            startDate          : moment().subtract(1, 'months'),
            endDate            : moment(),
            dateLimit          : {
                days: 60
            },
            showDropdowns      : true,
            showWeekNumbers    : true,
            timePicker         : false,
            timePickerIncrement: 1,
            timePicker12Hour   : true,
            ranges             : {
                'Today'       : [moment(), moment()],
                'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month'  : [moment().startOf('month'), moment().endOf('month')],
                'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            opens              : 'left',
            buttonClasses      : ['btn btn-default'],
            applyClass         : 'btn-small btn-primary',
            cancelClass        : 'btn-small',
            format             : 'MM/DD/YYYY',
            separator          : ' to ',
            locale             : {
                applyLabel      : 'Submit',
                cancelLabel     : 'Clear',
                fromLabel       : 'From',
                toLabel         : 'To',
                customRangeLabel: 'Custom',
                daysOfWeek      : ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                monthNames      : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                firstDay        : 1
            }
        };

        $('#chart_bounces_range span').html(moment().subtract(1, 'months').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY'));
        $('#chart_bounces_range').daterangepicker(optionSet1, cb);
        $('#chart_bounces_range').on('show.daterangepicker', function () {
            //console.log("show event fired");
        });
        $('#chart_bounces_range').on('hide.daterangepicker', function () {
            //console.log("hide event fired");
        });
        $('#chart_bounces_range').on('apply.daterangepicker', function (ev, picker) {
            //console.log("apply event fired, start/end dates are " + picker.startDate.format('MMMM D, YYYY') + " to " + picker.endDate.format('MMMM D, YYYY'));
        });
        $('#chart_bounces_range').on('cancel.daterangepicker', function (ev, picker) {
            //console.log("cancel event fired");
        });
    }

}(jQuery));
/**
 * Created by PULSARINARA\r.mill on 29/08/18.
 */

(function ($) {
    "use strict";

    $(document).ready(function () {
        if ($('.select_item').length) {
            $('.select_item').change(function () {
                loadItems($(this));
            })
        }

        var loadItems = function (item) {
            console.log(item.val());
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url'),
                data    : {
                    item_id: item.val(),
                },
                success : function (data) {
                    // console.log(data);

                    $.each(data, function (name) {
                        if ($("#" + name).length) {
                            $("#" + name).find('option').remove();
                            $.each(this, function (i, e) {
                                $("#" + name).append('<option value="' + e.uid + '">' + e.name + '</option>');
                            });
                        }
                    });
                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR', jqXHR);
                }
            })
        };

        if ($('.select_items_informs').length) {
            $('.select_items_informs').change(function () {
                loadItemsInforms($(this));
            })
        }

        var loadItemsInforms = function (item) {
            console.log(item.val());
            var user_id                = $('#user_id').val();
            var campaign_uid           = $('#campaign_uid').val();
            var destination_uid        = $('#destination_uid').val();
            var destination_farmer_uid = $('#destination_farmer_uid').val();
            var origin_uid             = $('#origin_uid').val();
            var origin_rancher_uid     = $('#origin_rancher_uid').val();
            var tank_uid               = $('#tank_uid').val();
            var driver_id              = $('#driver_id').val();

            var range_enabled     = $('#range_enabled').prop('checked');
            var date_range_inform = $('#date_range_inform').val();
            $.ajax({
                    type    : "POST",
                    dataType: "json",
                    url     : $('#url_informs').val(),
                    data    : {
                        user_id               : user_id,
                        campaign_uid          : campaign_uid,
                        destination_uid       : destination_uid,
                        destination_farmer_uid: destination_farmer_uid,
                        origin_uid            : origin_uid,
                        origin_rancher_uid    : origin_rancher_uid,
                        range_enabled         : range_enabled,
                        date_range_inform     : date_range_inform,
                        tank_uid              : tank_uid,
                        driver_id             : driver_id
                    },
                    success : function (data) {
                        console.log(data);
                        $("#campaign_uid").find('option').remove();
                        $("#destination_uid").find('option').remove();
                        $("#destination_farmer_uid").find('option').remove();
                        $("#origin_uid").find('option').remove();
                        $("#origin_rancher_uid").find('option').remove();
                        $("#tank_uid").find('option').remove();
                        $("#driver_id").find('option').remove();

                        if (user_id) {
                            {
                                $("#campaign_uid").append('<option value="0">&lt;' + data.all + '&gt;</option>');
                                $.each(data.campaigns, function (i, e) {
                                    $("#campaign_uid").append('<option value="' + e.uid + '">' + e.name + '</option>');
                                });

                                if (campaign_uid) {
                                    $('#campaign_uid').val(campaign_uid);
                                } else {
                                    $('#campaign_uid').val(0);
                                }
                            }
                            {
                                $("#destination_uid").append('<option value="0">&lt;' + data.all + '&gt;</option>');
                                $.each(data.destinations, function (i, e) {
                                    $("#destination_uid").append('<option value="' + e.uid + '">' + e.name + '</option>');
                                });

                                if (destination_uid) {
                                    $('#destination_uid').val(destination_uid);
                                } else {
                                    $('#destination_uid').val(0);
                                }
                            }

                            {
                                $("#destination_farmer_uid").append('<option value="0">&lt;' + data.all + '&gt;</option>');
                                $.each(data.farmers, function (i, e) {
                                    $("#destination_farmer_uid").append('<option value="' + e.uid + '">' + e.name + '</option>');
                                });

                                if (destination_farmer_uid) {
                                    $('#destination_farmer_uid').val(destination_farmer_uid);
                                } else {
                                    $('#destination_farmer_uid').val(0);
                                }
                            }

                            {
                                $("#origin_uid").append('<option value="0">&lt;' + data.all + '&gt;</option>');
                                $.each(data.origins, function (i, e) {
                                    $("#origin_uid").append('<option value="' + e.uid + '">' + e.name + '</option>');
                                });

                                if (origin_uid) {
                                    $('#origin_uid').val(origin_uid);
                                } else {
                                    $('#origin_uid').val(0);
                                }
                            }

                            {
                                $("#origin_rancher_uid").append('<option value="0">&lt;' + data.all + '&gt;</option>');
                                $.each(data.ranchers, function (i, e) {
                                    $("#origin_rancher_uid").append('<option value="' + e.uid + '">' + e.name + '</option>');
                                });

                                if (origin_rancher_uid) {
                                    $('#origin_rancher_uid').val(origin_rancher_uid);
                                } else {
                                    $('#origin_rancher_uid').val(0);
                                }
                            }

                            {
                                $("#tank_uid").append('<option value="0">&lt;' + data.all + '&gt;</option>');
                                $.each(data.tanks, function (i, e) {
                                    $("#tank_uid").append('<option value="' + e.uid + '">' + e.name + '</option>');
                                });

                                if (tank_uid) {
                                    $('#tank_uid').val(tank_uid);
                                } else {
                                    $('#tank_uid').val(0);
                                }
                            }

                            {
                                $("#driver_id").append('<option value="0">&lt;' + data.all + '&gt;</option>');
                                $.each(data.drivers, function (i, e) {
                                    $("#driver_id").append('<option value="' + e.uid + '">' + e.name + '</option>');
                                });

                                if (driver_id) {
                                    $('#driver_id').val(driver_id);
                                } else {
                                    $('#driver_id').val(0);
                                }
                            }
                        }
                    },
                    error   : function (jqXHR, textStatus, errorThrown) {
                        console.log('[filterById] ERROR', jqXHR);
                    }
                }
            )
        };
    });
})
(jQuery);


$(document).ready(function () {
    getMapData();
});

/**
 * function to get the map data by a JSON
 */
function getMapData() {
    if(document.getElementById('vmap')) {

        var url = $('#vmap').data('url');

        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            cache: false,
            success: function(result) {
                //console.log('getMapData success', result);
                ///alert('OK');
                if (result) {
                    buildMapTable(result.labels);
                    init_map(result.datas);
                }
            },
            error : function(xhr, status, error) {
                console.log('error');
            }
        });
    }
}

/**
 * build and initialize the map with the data received
 * formated and with actions
 * @param JSON gpdData
 */
function init_map (gdpData)
{
    //var gdpData =  {"af":"16.63","al":"11.58","dz":"158.97","ao":"85.81","ag":"1.1","es":"351.02"};
    //Then connect it to the page and add some code to make visualization:

    jQuery('#vmap').vectorMap({
        map: 'world_en',
        zoomOnScroll: true,
        backgroundColor: null,
        color: 'gray',
        hoverOpacity: 0.7,
        selectedColor: '#666666',
        enableZoom: true,
        showTooltip: true,
        values: gdpData,
        scaleColors: ['#C8EEFF', '#006491'],
        normalizeFunction: 'polynomial',
        onLabelShow: function (event, label, code) {
            var countryName = label[0].innerHTML;
            var visits = gdpData[code];

            if (visits == undefined) {
                visits = 0;
            }

            var html = ['<b>Name:</b> ',
                countryName,
                '<br/><b>Code:</b> &nbsp;&nbsp;',
                code,
                '<br/><b>Visits:</b> &nbsp;&nbsp;',
                visits
            ].join("");
            label[0].innerHTML = html;
        }
    });
}

/**
 * build the map table with the labels received
 * @param JSON labels
 */
function buildMapTable (labels)
{
    var $table = $('<table/>');

    /*remove all of the divs*/
//     $('div').each(function(index, item) {
//         $(item).remove();
//     });

    $.each(labels, function(country, value) {
        $table.append( '<tr><td>' + country + '</td><td class="fs15 fw700 text-right">' + Math.round(value) + '%</td></tr>' );
    });
    $('#mapTable').append($table);
}
/**
 * Created by PULSARINARA\r.mill on 13/03/18.
 */
(function ($) {
    $(document).ready(function () {
        if ($('.select-icon-preview').length) {
            $('.select-icon-preview').change(function (value1, value) {
                $('#icon').attr('class', 'col-md-7 col-xs-12 fa fa-2x');
                // $('#icon').addClass('fa');
                // $('#icon').addClass('fa-x2');
                $('#icon').addClass($(this).val());
            });
        }
    });
})(jQuery);
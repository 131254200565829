/**
 * b.rando@agrointelligent.com
 *
 * 13/07/21

 */

var used_sensors = [];
var used_actuators = [];

$(document).ready(function() {
  removableSensor($('.remove-sensor'));
});

function update_used(io_type) {
  $(".custom-sensor:visible option").each(function() {
    // Desactiva la opción escogida en el resto de desplegables
    if (jQuery.inArray($(this).val(), used_sensors) != -1) {
      $(this).attr('hidden', true);
    } else {
      // Activa las opciones deseleccionadas
      if ($(this).val() != 0) {
        $(this).removeAttr('hidden');
      }
    }
  });
}

function update_used_sensors() {
  $(".custom-sensor:visible option").each(function() {
    // Desactiva la opción escogida en el resto de desplegables
    if (jQuery.inArray($(this).val(), used_sensors) != -1) {
      $(this).attr('hidden', true);
    } else {
      // Activa las opciones deseleccionadas
      if ($(this).val() != 0) {
        $(this).removeAttr('hidden');
      }
    }
  });
}

function update_used_actuators() {
  $(".custom-actuator:visible option").each(function() {
    // Desactiva la opción escogida en el resto de desplegables
    if (jQuery.inArray($(this).val(), used_actuators) != -1) {
      $(this).attr('hidden', true);
    } else {
      // Activa las opciones deseleccionadas
      if ($(this).val() != 0) {
        $(this).removeAttr('hidden');
      }
    }
  });
}

function removableSensor(button) {
  button.click(function(event) {
    var selected = $(this).closest('.form-group').find('.custom-sensor');
    var typeId = $('.io-mutator').val();

    if (selected.attr('data-old-value') !== undefined) {
      used_sensors.splice(
        used_sensors.indexOf(selected.attr('data-old-value')), 1);
    }
    update_used_sensors();

    $(this).closest('.form-group').remove();
    var sensorNum = $('.custom-sensor:visible').length;

    if (typeId) {
      if (sensorNum < maxes[typeId].max_in) {
        $('#add_sensor').show();
      }
    }
  });
}

// Agrega un nuevo formulario clave-valor
$(document).ready(function() {
  $('#add_json_param').click(function(event) {
    var template = $('#tab_content_template').html();
    $('#customs').append(template);
  })
});

// Limita los sensores y actuadores que puede tener un nodo según su tipo
$(document).ready(function() {
  var typeId = $('.io-mutator').val();
  var sensorNum = $('.custom-sensor:visible').length;

  if (typeId) {
    if (maxes[typeId].max_in == 0 || sensorNum >= maxes[typeId].max_in) {
      $('#add_sensor').hide();
    }
    if (maxes[typeId].max_out == 0) {
      $('#add_actuator').hide();
    }
  }

$('.io-mutator').on('change', function() {
  var typeId = $(this).val();
    var sensorNum = $('.custom-sensor:visible').length;
    var actuatorNum = $('.custom-actuator:visible').length;

  if (typeId) {

      if (sensorNum >= maxes[typeId].max_in) {
        $('#add_sensor').hide();
      } else
        $('#add_sensor').show();

      if (actuatorNum >= maxes[typeId].max_out) {
        $('#add_actuator').hide();
      } else
        $('#add_actuator').show();

      while (sensorNum > maxes[typeId].max_in
        || actuatorNum > maxes[typeId].max_out) {
          if (sensorNum > maxes[typeId].max_in) {

            var selected = $('#custom_sensors > div').last().find('.custom-sensor');
            if (selected.attr('data-old-value') !== undefined) {
              used_sensors.splice(
                used_sensors.indexOf(selected.attr('data-old-value')), 1);
      }

            $('#custom_sensors > div').last().remove();
            update_used_sensors();
            sensorNum = $('.custom-sensor:visible').length;
  } else {
            $('#custom_actuators > div').last().remove();
            actuatorNum = $('.custom-actuator:visible').length;
  }
      }
    }
});
});

// Añade un nuevo formulario para asignar un sensor a un nodo
$(document).ready(function() {
  $('#add_sensor').click(function(event) {
    var template = $('#link_sensor_template').html();
    var typeId = $('.io-mutator').val();
    var sensorNum = $('.custom-sensor:visible').length;

    if (typeId) {

      //Previene la creación de un formulario si max_in es 0
      if (sensorNum >= maxes[typeId].max_in) {
        $('#add_sensor').hide();
      } else {
        $('#custom_sensors').append(template);
        if (sensorNum == maxes[typeId].max_in - 1) {
          $('#add_sensor').hide();
        }
      }
    }

    update_used_sensors();
    removableSensor(
      $('#custom_sensors').children().last().find('.remove-sensor'));
  });
});

// Añade un nuevo formulario para asignar un actuador a un nodo
$(document).ready(function() {
  $('#add_actuator').click(function(event) {
    var template = $('#link_actuator_template').html();
    var typeId = $('.io-mutator').val();
    var actuatorNum = $('.custom-actuator:visible').length;

    if (typeId) {

      // Previene la creación de un formulario si max_out es 0
      if (actuatorNum >= maxes[typeId].max_out) {
        $('#add_actuator').hide();
      } else {
        $('#custom_actuators').append(template);
        if (actuatorNum == maxes[typeId].max_out - 1) {
          $('#add_actuator').hide();
        }
      }
    }

    update_used_actuators();
    // Refactor función removableActuator
    $('#custom_actuators').children().last().find('.remove-actuator').click(function(event) {
      var selected = $(this).closest('.form-group').find('.custom-actuator');
      if (selected.attr('data-old-value') !== undefined) {
        // Si se deselecciona un actuador lo marca como disponible
        used_actuators.splice(used_actuators.indexOf(selected.attr('data-old-value')), 1);
      }

      update_used_actuators();

      $(this).closest('.form-group').remove();
      if (typeId) {
        if (actuatorNum < maxes[typeId].max_out) {
          $('#add_actuator').show();
        }
      }
    });
  });
});

// Actualiza los últimos valores de sensores y actuadores en la vista info de un nodo
$(document).ready(function() {
    function autoRefresh_div() {
      $("#sensor_updates_holder").load(window.location.href + " #sensor_updates");
      $("#actuator_updates_holder").load(window.location.href + " #actuator_updates");
    }
    setInterval(autoRefresh_div, 10000); // every 10 seconds
    autoRefresh_div();

  });

// Desactiva los sensores ya seleccionados del resto de desplegables
$("#custom_sensors").on("change", "select", function(event) {
  if ($(this).attr('data-old-value') !== undefined) {
    // Si se deselecciona un sensor lo marca como disponible
    used_sensors.splice(used_sensors.indexOf($(this).attr('data-old-value')), 1);
  }

  var selected = $(this).val();
  $(this).attr('data-old-value', selected);
  used_sensors.push(selected);
  update_used_sensors();
});

// Desactiva los actuadores ya seleccionados del resto de desplegables
$("#custom_actuators").on("change", "select", function(event) {
  if ($(this).attr('data-old-value') !== undefined) {
    // Si se deselecciona un actuador lo marca como disponible
    used_actuators.splice(used_actuators.indexOf($(this).attr('data-old-value')), 1);
  }

  var selected = $(this).val();
  $(this).attr('data-old-value', selected);
  used_actuators.push(selected);
  update_used_actuators();
});

// Lanza evento change para cada sensor enlazado (vista Edit),
// ocultándolo para que no vuelva a ser seleccionado
$(function() {
  $('.linked-sensor').each(function() {
    $(this).change();
  })
  // IDEM ACTUADORES
});

/**
 * Created by PULSARINARA\d.monfort on 27/08/21.
 */

(function ($) {
    "use strict";

    $(document).ready(function () {

        if (document.querySelector(".grid-stack")) {

            if (document.querySelector(".info-view")) {

                grid = GridStack.init({
                    disableDrag         : true,
                    disableResize       : true,
                    disableOneColumnMode: true,
                    margin              : 0,
                    minRow              : 5,
                })
            } else {
                grid = GridStack.init({
                    disableDrag         : false,
                    disableResize       : true,
                    disableOneColumnMode: true,
                    margin              : 0,
                    minRow              : 5,
                });

            }


            if (document.querySelector(".edit-view")) {
                grid.resumeElements();
                console.log("ENTRA");
            } else if (document.querySelector(".info-view")) {
                grid.resumeElements();
            } else {
                for (let i = 0; i < 5; i++) {
                    nuevoElemento(i, i, "");
                }
            }

            GridStack.prototype.AddElements = function (X, Y) {
                console.log("X:" + X + " Y:" + Y)
                console.log("x:" + (parseInt(X) - parseInt(1)) + "  y:" + parseInt(Y));
                grid.addWidget({
                    x      : parseInt(X) - parseInt(1), y: parseInt(Y), h: 1, w: 1, locked: true,
                    content: '<button onClick="grid.removeWidget(this.parentNode.parentNode)">X</button><br/>' +
                    '<button onClick="grid.AddElements($(this.parentNode.parentNode).attr(`gs-x`),' +
                    '$(this.parentNode.parentNode).attr(`gs-y`)); ' +
                    'console.log(`y:`+$(this.parentNode.parentNode).attr(`gs-y`));' +
                    'console.log(`x:`+$(this.parentNode.parentNode).attr(`gs-x`))">+</button>'
                });
                console.log("x:" + parseInt(X) + "  y:" + (parseInt(Y) + parseInt(1)));
                grid.addWidget({
                    x      : parseInt(X), y: parseInt(Y) + parseInt(1), h: 1, w: 1, locked: true,
                    content: '<button onClick="grid.removeWidget(this.parentNode.parentNode)">X</button><br/>' +
                    '<button onClick="grid.AddElements($(this.parentNode.parentNode).attr(`gs-x`),' +
                    '$(this.parentNode.parentNode).attr(`gs-y`));' +
                    'console.log(`y:`+$(this.parentNode.parentNode).attr(`gs-y`));' +
                    'console.log(`x:`+$(this.parentNode.parentNode).attr(`gs-x`))">+</button>'
                });
                console.log("x:" + (parseInt(X) + parseInt(1)) + "  y:" + parseInt(Y));
                grid.addWidget({
                    x      : parseInt(X) + parseInt(1), y: parseInt(Y), h: 1, w: 1, locked: true,
                    content: '<button onClick="grid.removeWidget(this.parentNode.parentNode)">X</button><br/>' +
                    '<button onClick="grid.AddElements($(this.parentNode.parentNode).attr(`gs-x`),' +
                    '$(this.parentNode.parentNode).attr(`gs-y`));' +
                    'console.log(`y:`+$(this.parentNode.parentNode).attr(`gs-y`));' +
                    'console.log(`x:`+$(this.parentNode.parentNode).attr(`gs-x`))">+</button>'
                });
            };



            GridStack.prototype.nuevoElemento = function (x, y, content, img) {
                // GridStack.prototype.nuevoElemento = function (x, y, content) {

                // nuevoElemento(x, y, content);
                nuevoElemento(x, y, content, img);
            };

            GridStack.prototype.menuModalClose = function () {
                menuModalClose();
            };

            GridStack.prototype.menuModal = function () {
                menuModal();
            }
            // $('.grid-stack-item-content.vacio').click(function (event) {
            //     console.log(this.parentNode);
            //
            //     // this.parentNode.attributes["gs-x"].value ++;
            //     // muestra el elemento modal.
            //     $('#myModal').modal({
            //         show: true
            //     })
            // });
            // evento de escucha del menu modal
            imgChosen();

        }

    })


    function nuevoElemento(x, y, content, img = "vacio", id = 1, w = 1, h = 1) {
        let elemento = grid.addWidget({
            x      : x,
            y      : y,
            w      : w,
            h      : h,
            id     : id,
            // content: '<button onClick=" grid.removeWidget(this.parentNode.parentNode);  ">X</button>' + content,
            content: content,
            locked : true
        });
        elemento.childNodes[0].className += " " + img;
        elementChosen(elemento);

    }

    // function nuevoElemento(x, y, content, w = 1, h = 1) {
    //     let elemento = grid.addWidget({x: x, y: y, w: w, h: h, content: '<button onClick=" grid.removeWidget(this.parentNode.parentNode);  ">X</button>' + content, locked: true});
    //     elemento.childNodes[0].className += " vacio";
    //     menuModal(elemento);
    // }

    /**
     * Muestra el menú modal y guarda el elemento que ha sido elegido para usarlo más tarde.
     * @param elemento
     */
    function menuModal() {
        $('#myModal').modal({
            show: true,
            // keyboard :false, //por defecto es true
        })
    }


    /**
     * Recoge el click sobre el elemento y lo guarda
     * @param elemento
     */
    function elementChosen(elemento) {
        event.preventDefault();
        if ((".grid-stack").addEventListener) {

        } else {
            $(elemento.childNodes[0]).click(function (event) {
                menuModal();

            });
        }

    }


    function mouseX(evt) {
        if (evt.pageX) {
            return evt.pageX;
        } else if (evt.clientX) {
            return evt.clientX + (document.documentElement.scrollLeft ?
                    document.documentElement.scrollLeft :
                    document.body.scrollLeft);
        } else {
            return null;
        }
    }

    function mouseY(evt) {
        if (evt.pageY) {
            return evt.pageY;
        } else if (evt.clientY) {
            return evt.clientY + (document.documentElement.scrollTop ?
                    document.documentElement.scrollTop :
                    document.body.scrollTop);
        } else {
            return null;
        }
    }

    /**
     * Cierra el menú modal
     */
    function menuModalClose() {
        $('#myModal').modal('hide');
    }

    /**
     * Funcion que dentro del menu modal se encarga de recoger el elemento seleccionado
     *  Y convierte el elemento vacio en el seleccionado.
     */
    function imgChosen() {
        $('.grid-item').click(function (event) {
            // console.log(this.children[0].src);
            // clase para que no sea elegible
            if (this.classList.contains("invalid")) {
                return;
            } else {
                let hijo = chosen.childNodes[0];


                // Se mira si es elemento solo o con descripción
                if (this.classList["length"] > 1) {
                    hijo.classList.remove(hijo.classList[hijo.classList["length"] - 1]);
                    // console.log(this);
                    hijo.classList.add(this.classList[this.classList["length"] - 1]);
                } else {
                    //this.firstElementChild es el elemento que contiene la imagen.
                    hijo.classList.remove(hijo.classList[hijo.classList["length"] - 1]);
                    hijo.classList.add(this.firstElementChild.classList[this.firstElementChild.classList["length"] - 1]);
                }

                menuModalClose();
            }

        });
    }

    /**
     * Se le pasan los elementos del gridstack y los pasa a un array con la información que se quiere
     * @param elements
     * @returns {Array}
     */
    function save(elements) {
        let infoImp = [];
        elements.forEach(function (element) {
            if (element.el.firstChild.classList[element.el.firstChild.classList.length - 1] != "vacio") {
                let e = {
                    x        : element.x,
                    y        : element.y,
                    w        : element.w,
                    h        : element.h,
                    img      : element.el.firstChild.classList[element.el.firstChild.classList.length - 1],
                    node     : "",
                    device_id: "",
                };
                infoImp.push(e);
            }
        });
        console.log(infoImp);
        return infoImp;
    }

    GridStack.prototype.updateElements = function () {
        $('#Nelementos').val(JSON.stringify(save(grid.engine.nodes)));
    };

    /**
     * Función para guardar un array con elementos en el html para luego recuperarlos con el php
     */
    GridStack.prototype.saveElements = function () {

        $('#elementos').val(JSON.stringify(save(grid.engine.nodes)));
    };

    /**
     * Funcion que se encarga de construir los elementos recuperados del php y construir el
     *  GridStack a partir de estos elementos.
     */
    GridStack.prototype.resumeElements = function () {
        let infoImp = [];
        infoImp = JSON.parse($('#elementos').val());
        infoImp.forEach(function (element) {

            nuevoElemento(element.x, element.y, "", element.img);
        });

    };


})(jQuery);


(function ($) {
    // console.log(elementos);
    $(document).ready(function () {
        if (document.querySelector("#myModal")) {
            let elementos = Object.entries(JSON.parse($('#modal').val()));
            elementos.forEach(function (element) {
                let one = element[0];
                let title = element[1][1]["class"];
                // console.log(element[1][1]);
                $('#accordion')
                    .append(
                        $('<div/>')
                            .addClass("panel")
                            .addClass("panel-default")
                            .append(
                                $("<div/>")
                                    .addClass('panel-heading')
                                    .attr('role', 'tab')
                                    .attr('id', 'heading' + one)
                                    .append(
                                        $("<h4/>")
                                            .addClass("panel-title")
                                            .append(
                                                $('<a/>')
                                                // .addClass('collapsed')
                                                    .attr("role", "button")
                                                    .attr("data-toggle", "collapse")
                                                    .attr("data-parent", "#accordion")
                                                    .attr("href", "#collapse" + one)
                                                    .append(
                                                        $("<b/>")
                                                            .text(title)
                                                    )
                                            )
                                    )
                            )
                            .append(
                                $("<div/>")
                                    .addClass('panel-collapse')
                                    .addClass('collapse')
                                    //hace que todos los menús esten extendidos
                                    // .addClass('in')
                                    .attr('id', 'collapse' + one)
                                    .attr('role', 'tabpanel')
                            )
                    );
            });

            elementos.forEach(function (element) {
                let one = element[0];
                let structure = "";

                structure += "<div class='panel-body'>";

                if (one == "MeasuringDevice") {
                    structure += "<div class='grid-container devices'>"
                    element[1].forEach(function (item) {

                        structure += "<div class='grid-item'>" +
                            "<div class=' col-sm-12 col-md-5 img-wide-modal " + item['image'] + "'></div>" +
                            "<div class='col-md-7 col-sm-6'>" +
                            "<h1>" + item['name'] + "</h1>" +
                            "<p>" + item['description'] + "</p>" +
                            "</div> " +
                            "</div>";
                    })
                } else {
                    structure += "<div class='grid-container'>"
                    element[1].forEach(function (item) {

                        structure += "<div class='grid-item img-modal " + item['image'] + "'>" +
                            "</div>";
                    })
                }

                structure += "</div>" +
                    "</div>";

                if (document.querySelector("#collapse" + one)) {
                    $("#collapse" + one)
                        .append(
                            structure
                        );

                    $(".grid-item").click(function (event) {

                        // clase para que no sea elegible
                        if (this.classList.contains("invalid")) {
                            return;
                        } else {
                            let hijo = chosen.childNodes[0];


                            // Se mira si es elemento solo o con descripción
                            if (this.classList["length"] > 1) {
                                hijo.classList.remove(hijo.classList[hijo.classList["length"] - 1]);
                                // console.log(this);
                                hijo.classList.add(this.classList[this.classList["length"] - 1]);
                            } else {
                                //this.firstElementChild es el elemento que contiene la imagen.
                                hijo.classList.remove(hijo.classList[hijo.classList["length"] - 1]);
                                hijo.classList.add(this.firstElementChild.classList[this.firstElementChild.classList["length"] - 1]);
                            }

                            grid.menuModalClose();
                        }

                    })
                }
            });

        }

    })


})(jQuery);

